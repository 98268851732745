import i18n from '@/libs/i18n';

/* eslint-disable */
// prettier-ignore
export default {
  pages: {
    key: 'title',
    data: [

      {
        title: i18n.t('nav.dashboard'),
        icon: 'home-icon',
        route: {name:'dashboard'},
      },
      {
        title: i18n.t('nav.orders'),
        icon: 'orders-icon',
        route: {name:'orders'},
      },
      {
        title: i18n.t('nav.products'),
        icon: 'products-icon',
        route: {name:'products'},
      },
      {
        title: i18n.t('nav.categories'),
        icon: 'categories-icon',
        route: {name:'categories'},
      },

      {
        title: i18n.t('nav.financial'),
        icon: 'money-icon',
        route: {name:'financial'},
      },
      {
        title: i18n.t('nav.reviews'),
        icon: 'star-icon',
        route: {name:'reviews'},
      },
      {
        title: i18n.t('nav.settings'),
        icon: 'setting-icon',
        route: {name:'settings'},
      },
    ],
  },


}
/* eslint-enable */
