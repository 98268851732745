export default [
    {
        title: 'dashboard',
        icon: 'home-icon',
        tagVariant: 'light-warning',
        route: 'dashboard',
    },
    {
        title: 'orders',
        icon: 'orders-icon',
        tagVariant: 'light-warning',
        route: 'orders',
    },
    {
        title: 'products',
        icon: 'products-icon',
        tagVariant: 'light-warning',
        route: 'products',
    },
    {
        title: 'categories',
        icon: 'categories-icon',
        tagVariant: 'light-warning',
        route: 'categories',
    },
    // {
    //     title: 'reports',
    //     icon: 'status-up-icon',
    //     tagVariant: 'light-warning',
    //     route: 'reports',
    // },

    {
        title: 'discounts',
        icon: 'coupons-icon',
        tagVariant: 'light-warning',
        route: 'discounts',
    },
    {
        title: 'financial',
        icon: 'money-icon',
        tagVariant: 'light-warning',
        route: 'financial',
    },
    {
        title: 'reviews',
        icon: 'star-icon',
        tagVariant: 'light-warning',
        route: 'reviews',
    },
    {
        title: 'settings',
        icon: 'setting-icon',
        tagVariant: 'light-warning',
        route: 'settings',
    },
];
