<template>
  <ul v-if="getProfileInfo">
    <li class="nav-item d-flex align-items-center company-info">

      <img v-if="getProfileInfo.image && showImage" :src=getProfileInfo.image :alt="getProfileInfo.name" @error="onImageError"
           class="company-logo mr-4">
      <div v-else class="company-logo  mr-4">
       <span>
          <d-person-icon/>
       </span>
      </div>

      <div class="d-flex flex-column">

        <span :title="getProfileInfo.name" class="menu-title text-white text-med-18">
          {{ shortenText(getProfileInfo.name, 10) }}
        </span>

        <span class="menu-title text-truncate text-reg-14 menu-sub-title">
          {{ getProfileInfo.user_type }}
        </span>

      </div>


    </li>

    <component
        :is="resolveNavItemComponent(item)"
        v-for="item in this.items"
        :key="item.header || item.title"
        :item="item"
    />

  </ul>
</template>

<script>
import {resolveVerticalNavMenuItemComponent as resolveNavItemComponent} from '@core/layouts/utils';
import {provide, ref} from '@vue/composition-api';
import {mapGetters} from 'vuex';
import VerticalNavMenuHeader from '../vertical-nav-menu-header';
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue';
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue';

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  data() {
    return {
      showImage: true,
    }
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getProfileInfo: 'vendor/settings/profile/getBasic',
    }),
  },
  methods: {
    onImageError() {
      this.showImage = false;
    },
    shortenText(text, length) {
      return text.length > length ? text.split('').splice(0, length).join('').concat('', ' ...') : text;
    },
    filteredItems(val) {
      const [reports] = val.restrictions.filter(
          item => item.name === 'reports',
      );
      const [dashboard] = val.restrictions.filter(
          item => item.name === 'dashboard',
      );
      if (
          val.complete_status === 'pending'
          || val.complete_status === 'confirming'
          || val.complete_status === 'suspended'
          || val.complete_status === 'rejected'
      ) {
        return [this.items[0]];
      } else if (dashboard.code === '02_07_01' || reports.code === '02_06_01') {
        const items = this.items.filter(item => {
          if (item.title === 'financial_operations' || item.title === 'dashboard') {
            return;
          }
          return item;
        });
        return items;
      }
      return this.items;
    },
  },

  setup() {
    provide('openGroups', ref([]));

    return {
      resolveNavItemComponent,
    };
  },
};
</script>
