<template>
  <div>
    <layout-vertical>
      <router-view />
    </layout-vertical>
    <wameed-loading-overlay  :isLoading="getloading"/>
  </div>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue';
import { $themeConfig } from '@themeConfig';
import {WameedLoadingOverlay}  from 'wameed-ui/dist/wameed-ui.esm'; 
import { mapGetters } from 'vuex'
export default {
  components: {
    LayoutVertical,
    WameedLoadingOverlay,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    };
  },
  computed: {
    ...mapGetters({ getloading: 'getloading' }),
  },
  created() {
    this.$store.dispatch('vendor/settings/profile/basic');
    this.$store.dispatch('vendor/notifications/limit')
  }
};
</script>
