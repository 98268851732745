

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  render(h) {
    const span = h('span', {}, this.$i18n.t('nav.'+this.item.header))
      // TODO: change icon 
    const icon = h(this.item.icon)
    // if (canViewVerticalNavMenuHeader(this.item)) {
      return h('li', { class: 'navigation-header text-truncate' }, [span, icon])
    // }
    // return h()
  },
}
